import React from "react";
import {SideNavigation} from "./structure/side-navigation/SideNavigation";
import {HashRouter} from "react-router-dom";
import {MainContent} from "./structure/main-content/MainContent";
import {PageFooter} from "./structure/page-footer/PageFooter";
import {createTheme, ThemeProvider} from "@material-ui/core";

import ("./App.css")

const theme = createTheme({
    typography: {
        fontFamily: "main-font",
        h3: {
            fontSize: 45,
            fontWeight: "normal",
        },
        h4: {
            fontSize: 35,
            fontWeight: "normal",
        },
        h5: {
            fontSize: 30,
            fontWeight: "normal",
        },
        h6: {
            fontSize: 25,
            fontWeight: "normal",
        },
        normal: {
            fontSize: 30,
            fontWeight: "normal",
        },
    }
})

export const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <HashRouter>
                    <MainContent/>
                    <SideNavigation/>
                    <PageFooter/>
                </HashRouter>
            </ThemeProvider>
        </>
    );
}
