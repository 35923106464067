import React, {useState} from "react";
import {BigPic} from "./BigPic";
import CloseIcon from "@material-ui/icons/Close";

import ("./Gallery.css");

export const Gallery = ({images}) => {
    const [isBigPicShown, setIsBigPicShown] = useState(false);
    const [bigPic, setBigPic] = useState("");

    const showBigImage = (imgToShow) => {
        setBigPic(imgToShow);
        setIsBigPicShown(true);
    }

    const {imgSrc, title, description} = bigPic;
    return (
        <React.Fragment>
            <div className="gallery-container">
                <div className={isBigPicShown ? "bigPic open" : "bigPic"}>
                    <BigPic title={title} description={description} img={imgSrc}/>
                    <CloseIcon onClick={() => setIsBigPicShown(false)}/>
                </div>
                <div className="gallery">
                    {images.map((image, index) => {
                        return (
                            <div className="pics" key={image.id} onClick={() => showBigImage(image)}>
                                <img alt={image.title} src={image.imgSrc} style={{width: "100%"}}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}
