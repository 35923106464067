import React, {useEffect} from "react";
import {Gallery} from "../../shared/gallery/Gallery";
import blechmuecke2014 from "../../../resources/images/art/Blechmücke 2014.JPG"
import changel from "../../../resources/images/art/ChAngel.jpg"
import depri from "../../../resources/images/art/Depri.jpg"
import springer from "../../../resources/images/art/Springer.jpg"
import bogenschuetze2015 from "../../../resources/images/art/Bogenschütze 2015.JPG"
import galaxie1 from "../../../resources/images/art/Galaxie 1.jpg"
import gottesanbeterinnen2016 from "../../../resources/images/art/Gottesanbeterinnen 2016.jpg"
import heuschnecke from "../../../resources/images/art/Heuschnecke.JPG"
import heuschrecke from "../../../resources/images/art/Heuschrecke.JPG"
import iRobert2018 from "../../../resources/images/art/I Robert 2018.jpg"
import impala2021 from "../../../resources/images/art/Impala 2021.jpg"
import kastenflosser2019 from "../../../resources/images/art/Kastenflosser 2019.jpg"
import pteranodon2013 from "../../../resources/images/art/Pteranodon 2013.JPG"
import stegosaurus2011 from "../../../resources/images/art/Stegosaurus 2011.JPG"
import tRex2012 from "../../../resources/images/art/TRex 2012.JPG"
import taenzerin1_2021 from "../../../resources/images/art/Tänzerin 1 2021.jpg"
import taenzerin2_2021 from "../../../resources/images/art/Tänzerin 2 2021.jpg"
import yoda2InCorten2017 from "../../../resources/images/art/Yoda 2 in Corten2017.jpg"
import blumen from "../../../resources/images/art/Blumen.JPG";
import elchBlankstahl from "../../../resources/images/art/Elch Blankstahl.jpg";
import elchCortenstahl from "../../../resources/images/art/Elch Cortenstahl.jpg";
import hasen from "../../../resources/images/art/Hasen.jpg";
import longhorn from "../../../resources/images/art/Longhorn 2021.jpg";
import bueste2021 from "../../../resources/images/art/Bueste 2021.JPG";
import flamingo from "../../../resources/images/art/Flamingo.jpeg";
import swingCircle from "../../../resources/images/art/SwingCircle.jpeg";
import schmetterling from "../../../resources/images/art/Schmetterling blank.jpg";
import rostSpanner from "../../../resources/images/art/Rostspanner.jpg";

export const ArtGallery = ({onMount}) => {

    useEffect(() => {
        onMount("\"(H)ART\"");
    }, []);


    return (
        <Gallery images={images}/>
    )
}

const images = [
    {
        id: 1,
        imgSrc: blechmuecke2014,
        title: "Blechmücke",
        description: "2014"
    },
    {
        id: 2,
        imgSrc: bogenschuetze2015,
        title: "Bogenschütze",
        description: "2015"
    },
    {
        id: 3,
        imgSrc: galaxie1,
        title: "Galaxie 1",
        description: "2019"
    },
    {
        id: 4,
        imgSrc: gottesanbeterinnen2016,
        title: "Gottesanbeterinnen",
        description: "2016"
    },
    {
        id: 5,
        imgSrc: heuschnecke,
        title: "Heuschnecke",
        description: "2018"
    },
    {
        id: 6,
        imgSrc: heuschrecke,
        title: "Heuschrecke",
        description: "2018"
    },
    {
        id: 7,
        imgSrc: iRobert2018,
        title: "I Robert",
        description: "2018"
    },
    {
        id: 8,
        imgSrc: impala2021,
        title: "Impala",
        description: "2021"
    },
    {
        id: 9,
        imgSrc: kastenflosser2019,
        title: "Kastenflosser",
        description: "2019"
    },
    {
        id: 10,
        imgSrc: pteranodon2013,
        title: "Pteranodon",
        description: "2013"
    },
    {
        id: 11,
        imgSrc: stegosaurus2011,
        title: "Stegosaurus",
        description: "2011"
    },
    {
        id: 12,
        imgSrc: tRex2012,
        title: "TRex",
        description: "2012"
    },
    {
        id: 13,
        imgSrc: taenzerin1_2021,
        title: "Tänzerin 1",
        description: "2021"
    },
    {
        id: 14,
        imgSrc: taenzerin2_2021,
        title: "Tänzerin 2",
        description: "2021"
    },
    {
        id: 15,
        imgSrc: longhorn,
        title: "Longhorn",
        description: "2021"
    },
    {
        id: 16,
        imgSrc: yoda2InCorten2017,
        title: "Yoda Corten",
        description: "2017"
    },
    {
        id: 17,
        imgSrc: blumen,
        title: "Blumen",
        description: ""
    },
    {
        id: 19,
        imgSrc: elchBlankstahl,
        title: "Elch Blankstahl",
        description: "2020"
    },
    {
        id: 20,
        imgSrc: elchCortenstahl,
        title: "Elch Cortenstahl",
        description: "2020"
    },
    {
        id: 21,
        imgSrc: hasen,
        title: "Hasen",
        description: ""
    },
    {
        id: 22,
        imgSrc: bueste2021,
        title: "Büste",
        description: "2021"
    },
    {
        id: 23,
        imgSrc: flamingo,
        title: "Flamingo",
        description: "2022"
    },
    {
        id: 24,
        imgSrc: swingCircle,
        title: "Swinging Circle",
        description: "2022"
    },
    {
        id: 25,
        imgSrc: schmetterling,
        title: "Schmetterling Blankstahl",
        description: "2022"
    },
    {
        id: 26,
        imgSrc: rostSpanner,
        title: "Rostspanner Corten",
        description: "2022"
    },
    {
        id: 27,
        imgSrc: changel,
        title: "ChAngel Blank & Corten",
        description: "2019"
    },
    {
        id: 28,
        imgSrc: depri,
        title: "Depri",
        description: "2023"
    },
    {
        id: 29,
        imgSrc: springer,
        title: "Springer",
        description: "2023"
    }
]