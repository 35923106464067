import React from "react";
import {Card} from "@material-ui/core";
import "./PageContent.css"

export const PageContent = ({children}) => {
    return (
        <div className="page-content-container">
            <Card className="page-main-card" variant="outlined">{children}</Card>
        </div>
    );
}
