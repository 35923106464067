import React, {useState} from "react";
import {PageHeader} from "../page-header/PageHeader";
import {PageContent} from "../page-content/PageContent";
import {Route, Switch} from "react-router-dom";
import {Contact} from "../../pages/contact/Contact";
import {useGlobalStore} from "../../global-store/GlobalStoreContext";
import {useObserver} from "mobx-react";
import {Privacy} from "../../pages/privacy/Privacy";
import {Impressum} from "../../pages/impressum/Impressum";
import {ArtGallery} from "../../pages/art-gallery/ArtGallery";

import("./MainContent.css")

export const MainContent = () => {
    const adjustPageTitle = (newTitle) => {
        setPageTitle(newTitle)
    }

    const globalStore = useGlobalStore();
    const [pageTitle, setPageTitle] = useState("Willkommen");

    return useObserver(() => (
            <div onClick={() => {
                if (globalStore.isNavOpen) {
                    globalStore.toggleNav();
                }
            }
            }
                 className={globalStore.isNavOpen ? "main-content dark" : "main-content"}>
                <PageHeader pageTitle={pageTitle}/>

                <PageContent>
                    <Switch>
                        <Route path="/contact" render={(props) => (
                            <Contact {...props} onMount={adjustPageTitle}/>
                        )}/>
                        <Route path="/impressum" render={(props) => (
                            <Impressum {...props} onMount={adjustPageTitle}/>
                        )}/>
                        <Route path="/privacy" render={(props) => (
                            <Privacy {...props} onMount={adjustPageTitle}/>
                        )}/>
                        <Route path="/*" render={(props) => (
                            <ArtGallery {...props} onMount={adjustPageTitle}/>
                        )}/>/>
                    </Switch>
                </PageContent>
            </div>
        )
    )
}