import React from "react";
import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {MenuAndLogo} from "../menu-and-logo/MenuAndLogo";
import {Link} from "react-router-dom";
import {useGlobalStore} from "../../global-store/GlobalStoreContext";
import {useObserver} from "mobx-react";
import brushIcon from "../../../resources/icons/brush.png"
import hammerIcon from "../../../resources/icons/hammer.png"
import homeIcon from "../../../resources/icons/home.png"
import mailIcon from "../../../resources/icons/mail.png"

import("./SideNavigation.css")

let navElementsMain = [
    {
        title: "(H)ART",
        icon: <div className="icon"><img alt="Brush" width="auto" height="100%" src={brushIcon}/></div>,
        linkTo: "/art"
    }
]
const navElementsSide = [
    {
        title: "Kontakt",
        icon: <div className="icon"><img alt="Mail" width="auto" height="100%" src={mailIcon}/></div>,
        linkTo: "/contact"
    }
]

export const SideNavigation = (props) => {
    const globalStore = useGlobalStore();

    return useObserver(() => (
        <Drawer
            variant="persistent"
            anchor="left"
            open={globalStore.isNavOpen}
            ModalProps={{onBackdropClick: globalStore.toggleNav}}
        >
            <Divider/>
            <MenuAndLogo/>
            <Divider/>
            <List>
                {navElementsMain.map((element, index) => (
                    <ListItem onClick={globalStore.toggleNav} button key={element.title} component={Link}
                              to={element.linkTo}>
                        <ListItemIcon>
                            {element.icon}
                        </ListItemIcon>
                        <ListItemText primary={element.title}/>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {navElementsSide.map((element, index) => (
                    <ListItem onClick={globalStore.toggleNav} button key={element.title} component={Link}
                              to={element.linkTo}>
                        <ListItemIcon>
                            {element.icon}
                        </ListItemIcon>
                        <ListItemText primary={element.title}/>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    ));
}
