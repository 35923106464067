import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {Button, IconButton} from "@material-ui/core";
import logo from "../../../resources/images/logo2.png"

import "./MenuAndLogo.css"
import {Link} from "react-router-dom";
import {useGlobalStore} from "../../global-store/GlobalStoreContext";

export const MenuAndLogo = () => {
    const globalStore = useGlobalStore();

    return (
        <div className="container">
            <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                    setTimeout(() => {
                        globalStore.toggleNav()
                    }, 10);
                }}
                sx={{mr: 2}}
            >
                <MenuIcon/>
            </IconButton>
            <Button
                onClick={globalStore.toggleNav}
                component={Link} to="/home">
                <img src={logo} alt="Logo Hizis"/>
            </Button>
        </div>
    );
}
